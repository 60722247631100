import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import "../../assets/styles/pages/login.css";
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ADMIN_LOGIN } from '../../graphql/queries/admin';
import { useState } from 'react';
import AlertGeneral from '../../components/general/AlertGeneral';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';

function Login(props) {
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const [openAlert, setOpenAlert] = useState(false);
    const [alert, setAlert] = useState({
        severity: "",
        message: ""
    });

    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({});

    // Debes importar tu mutación GraphQL y definirla
    const [login, { loading }] = useMutation(ADMIN_LOGIN, {
        update(proxy, { data: { adminLogin: { token: userToken, success} } }) {
            if(userToken!==null && success=== true){
                context.login(userToken);
                navigate('/dashboard');
            }else{
                setAlert({
                    ...alert,
                    message: "Usuario o contraseña incorrectos, intenta de nuevo",
                    severity: "error"
                });
                setOpenAlert(true);  
            }
            
        },
        onError(error) {
            setAlert({
                ...alert,
                message: "Usuario o contraseña incorrectos, intenta de nuevo",
                severity: "error"
            });
            setOpenAlert(true);
        },
        variables: {
            input: { ...values }
        }
    });

    const onChange = (event) => {
        const { name, value } = event.target;
        let error = '';
        if (name === 'email') {
            // Validar si el correo tiene un formato correcto
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                error = 'Ingresa un correo electrónico válido.';
            }
        } else if (name === 'password') {
            if (value.length < 8) {
                error = 'Ingresa un contraseña válida';
            }
        }
        setValues({
            ...values,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: error,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!Object.values(errors).some((error) => error !== '')) {
            try {
                const { data } = await login({
                    variables: {
                        email: values.email,
                        password: values.password,
                    },
                });
                  if(data.adminLogin.success === false){
                    console.log('Mutación exitosa: pero falsa', data,);
                  }
                
            } catch (error) {}
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box className="login-box">
                <Avatar className='loginAvatar'>
                    <KeyOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Iniciar sesión
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={onChange}
                        id="email"
                        label="Correo eléctronico"
                        name="email"
                        helperText={errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={onChange}
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        helperText={errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                    />
                    {loading ?
                        <Button
                            disabled
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Iniciando...
                        </Button>
                        :
                        errors.password || errors.email || values.email === '' || values.password === '' ?
                            <Button
                                disabled
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Iniciar sesión
                            </Button>
                            :
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Iniciar sesión
                            </Button>
                    }
                </form>
                {openAlert ?
                    <AlertGeneral
                        open={openAlert}
                        setOpen={setOpenAlert}
                        message={alert.message}
                        severity={alert.severity}
                    /> : null}
            </Box>
        </Container>
    );
}
export default Login;