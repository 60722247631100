import { Avatar } from "@mui/material";
import "../../../assets/styles/components/CardColorStyle.css";
import AssignmentIcon from '@mui/icons-material/Assignment';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { blue, blue_back, c_primary, c_primary_l, yellow, yellow_back } from "../../../utils/constants/ColorConst";
function ColorCard({ title, description, list, rightEnd, blueCard,yellowCard, iconCard, onclickCard, disabledIconLi, centerDesc, smallCard}) {

    return (
        <div className={smallCard ? "content-card-color-small": "content-card-color"} onClick={onclickCard}>
            <div className="header-card-color" style={{ backgroundColor: blueCard ? `${blue_back}` : yellowCard ? `${yellow_back}` :`${c_primary_l}` }}>
                <span className="text-card-color-title" style={{ color: blueCard ? `${blue}` : yellowCard ? `${yellow}` :`${c_primary}` }}>{title}</span>
                <Avatar className="avatar-color-card" sx={{ backgroundColor: blueCard ? `${blue}` : yellowCard ? `${yellow}` : `${c_primary}` }}>
                   {iconCard ? iconCard : <AssignmentIcon />}
                </Avatar>
            </div>
            <div className={centerDesc ? "center-desc" : "content-color-card"}>
                <span className="text-description-card-color" >
                    {description}</span>
                <ul className={disabledIconLi ? "list-description-colum" : "ul-list-description-card"}>
                    {list ?
                        list.map((item, idx) => {
                            return (
                                <li key={idx} className="list-description-card-color">
                                    {disabledIconLi ? null :
                                    <TaskAltOutlinedIcon fontSize="small" sx={{ mr: 1, color: blueCard ? `${blue}` : yellowCard ? `${yellow}` :`${c_primary}` }} /> }
                                    <span className="text-description-card-color">{item}</span>
                                </li>
                            )
                        }) : null}
                </ul>
                <div className="foter-description-card">{rightEnd}</div>
            </div>
        </div>
    )
};

export default ColorCard;