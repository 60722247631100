import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import AdminContext from "../../contexts/AdminContext";
import { Box, Container, CssBaseline, Divider, List, ListItem, ListItemButton, ListItemIcon, Toolbar, Tooltip, } from "@mui/material";
import { getRouter } from "../../router/Router";
import AppBarAdmin from "../../components/general/AppBarAdmin";
import logo from "../../assets/img/logo.png";
import { Drawer } from "./Drawer";
import { AuthContext } from "../../contexts/authContext";
import { ADMIN_PROFILE } from "../../graphql/queries/admin";
import "../../assets/styles/pages/dashboard.css";

function Dashboard() {
  const tabFlag = localStorage.getItem('tabDash');
  const rolFlag = localStorage.getItem('AR');
  const [adminName,setAdminName]=useState('');
  const [openAppBar, setOpenAppBar] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(tabFlag ? parseInt(tabFlag) : 0);
  const router = getRouter();
  const { t } = useTranslation("global");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const loggedUserJson = localStorage.getItem('token');

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    localStorage.setItem('tabDash', index)
  };

  useEffect(() => {
  }, [selectedIndex, rolFlag]);


  useEffect(() => {
    if (!user && (loggedUserJson === null || loggedUserJson === undefined)) {
      navigate('/login');
    }
    else{
    }
  }, [user, navigate, loggedUserJson]);


const {data,refetch}=useQuery(ADMIN_PROFILE,{
  onCompleted: () => {
    if(data.administratorProfile){
      let admin = data.administratorProfile;
      let rol = data.administratorProfile.roles.length> 0 ? data.administratorProfile.roles[0].name : 'ERROR';
      setAdminName(admin.name)
      localStorage.setItem('AR', rol)
    }
}
});

useEffect(() => {refetch();}, [refetch]);

  return (
    <AdminContext.Provider value={adminName}>
      <Box className="box-dashboard">
        <CssBaseline />
        <AppBarAdmin openAppBar={openAppBar} setOpenAppBar={setOpenAppBar} />
        <Drawer variant="permanent" open={openAppBar} sx={{ zIndex: 1200 }}>
          <Toolbar className="toolbar-dashboard" >
            <a href="/" aria-label="Ir a la página de inicio">
              <img src={`${logo}`} alt="Dalia Empower" className="logo-dashboard" />
            </a>
          </Toolbar>
          <div className="boxlist">
            <Divider />
            <br />
            <List>
              {router?.screens.map((item, idx) => {
                if (item.name) {
                  return (
                    <ListItem key={`List${idx}`} className="listItem-dashboard">
                      <Tooltip title={item.name} placement="right" arrow>
                        {openAppBar ?
                          <ListItemButton component={Link} itemScope="row"
                            to={item?.url}
                            selected={selectedIndex === idx}
                            onClick={(event) => handleListItemClick(event, idx)}
                            className="custom-list-item">
                            {item.icon}
                            <span style={{ marginLeft: 5 }}> {t(`Router.Connect.${item.name}`)} </span>
                          </ListItemButton>
                          :
                          <ListItemButton component={Link} itemScope="row"
                            to={item?.url}
                            selected={selectedIndex === idx}
                            onClick={(event) => handleListItemClick(event, idx)}
                            className="custom-list-item" sx={{ p: "5px", pl: "8px" }}>
                            <ListItemIcon className="custom-icon">{item.icon}</ListItemIcon>
                          </ListItemButton>
                        }
                      </Tooltip>
                    </ListItem>
                  );
                } return null;
              })}
            </List>
          </div>
        </Drawer>
        <Box className="boxContainer-dashboard">
          <Toolbar />
          <Container maxWidth="xl" className="container-dashboard">
            <React.Suspense fallback={""}>
              <Routes>
                {router?.screens.map((screen, idx) => {
                  return (
                    <Route key={`component${idx}`} path={screen.url} element={<screen.component />} />
                  )
                })}
              </Routes>
            </React.Suspense>
          </Container>
        </Box>
      </Box>
    </AdminContext.Provider>
  );
};
export default Dashboard;

