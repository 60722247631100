
import { ThemeProvider, createTheme } from '@mui/material';
import Dashboard from './pages/dashboard';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import Login from './pages/login';
import { c_secondary, c_primary, c_error_s, orange, orange_back, blue_back, blue } from './utils/constants/ColorConst';
import { useContext,} from 'react';
import { AuthContext } from './contexts/authContext';

function App() {
 const {user}=useContext(AuthContext);

  return (
    <ThemeProvider theme={theme}>
      
        <BrowserRouter>
          <Routes>
            {user ?
              <>
                <Route path='/*' element={<Dashboard />} />
              </>
              :
              <>
                <Route path='/login' element={<Login />} />
                <Route path='/*' element={<Login />} />
              </>
            }
          </Routes>
        </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;




const theme = createTheme({
  palette: {
    primary: {
      main: `${c_primary}`,
      light: '#F5F2FD',
    },
    secondary: {
      main: `${c_secondary}`
    },
    success: {
      main: `#096D2A`,
      light: `#EDFEF2`
    },
    error: {
      main: `${c_error_s}`
    },
    warning: {
      main: `${orange}`,
      light: `${orange_back}`
    },
    info:{
      main:`${blue_back}`,
      light:`${blue}`
    }
  }
})
