import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { client } from "./graphql/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initReactI18next,I18nextProvider } from 'react-i18next';
import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
import i18next from "i18next";
import { AuthProvider } from './contexts/authContext';

const storedFlag = localStorage.getItem('language');

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: storedFlag ? storedFlag : "es",
  resources: {
    en: {
      global: global_en
    },
    es: {
      global: global_es
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <I18nextProvider i18n={i18next}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    </I18nextProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
