import { gql } from "@apollo/client";

export const ADMIN_LOGIN = gql`
  mutation AdminLogin($input: LoginUserInput!) {
    adminLogin(input: $input) {
      success
      token
      refreshToken
    }
  }
`;

export const ADMIN_PROFILE = gql`
query administratorProfile{
  administratorProfile{
    _id
    email
    name
    roles{
      name
      description
      permissions{
        name
        description
      }
    }
  }
}
`;

export const GET_ADMINS = gql`
  query GetAdmins(
    $paging: OffsetPaging! = { limit: 10 }
    $sorting: [AdministratorSort!]! = []
  ) {
    administrators(paging: $paging, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        _id
        email
        name
        roles {
          name
          permissions {
            name
          }
        }

        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

export const GET_ADMIN = gql`
  query getAdmin($id: ID!) {
    administrator(id: $id) {
      _id
      email
      name
      createdAt
      updatedAt
      roles {
        _id
        name
        description
        permissions {
          _id
          name
          description
        }
      }
    }
  }
`;

//Mutations
export const CREATE_ADMIN = gql`
  mutation createAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      _id
      email
      name
    }
  }
`;

export const ASSIGN_ROLE_TO_ADMIN = gql`
  mutation assignRoleToAdmin($adminID: ID!, $roleName: String!) {
    assignRoleToAdmin(adminID: $adminID, roleName: $roleName) {
      _id
      email
      name
    }
  }
`;
