
import { jwtDecode } from "jwt-decode";
import { createContext, useReducer } from "react";

const initialState = {
    user: null
}

const token = localStorage.getItem("token");

if (token) {
    const decodeToken = jwtDecode(token);

    if (decodeToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
        localStorage.removeItem("tabDash");

    } else {
        initialState.user = decodeToken;
    }
} else {
    //cuando no se guarda algun valor en token
    localStorage.removeItem("token");
}

const AuthContext = createContext({
    user: null,
    login: (userData) => { },
    logout: () => { }

});

function authReducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload
            }
        case 'LOGOUT':
            return {
                ...state,
                user: null
            }
        default:
            return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const login = (userData) => {
        localStorage.setItem("token", userData);
        dispatch({
            type: 'LOGIN',
            payload: userData
        })
    };

    const logout=()=>{
        localStorage.removeItem("token");
        localStorage.removeItem("tabDash");
        localStorage.removeItem("tabC");
        localStorage.removeItem("tabDC");
        localStorage.removeItem("tabU");
        localStorage.removeItem("AR");
        dispatch({type:"LOGOUT"});
    };

    return (
        <AuthContext.Provider  value={{user:state.user, login, logout,}}
        {...props} />
    )
};
export {AuthContext, AuthProvider};