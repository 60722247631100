import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
    // Aquí es donde debes pasar el token
    const token = localStorage.getItem('token'); // Obtén el token de localStorage o cualquier otra fuente

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '', // Agrega el token a los encabezados de autorización si está disponible
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});


