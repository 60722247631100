import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Divider, styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import "../../assets/styles/pages/dashboard.css"
import flag_es from "../../assets/img/icons/flag_es.png";
import flag_en from "../../assets/img/icons/flag_en.png";
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AdminContext from '../../contexts/AdminContext';

function AppBarAdmin({ openAppBar, setOpenAppBar}) {
   const admin = useContext(AdminContext);
   const navigate = useNavigate();
   const [anchorElUser, setAnchorElUser] = React.useState(null);
   const [openMenuTranslation, setOpenMenuTranslation] = React.useState(null);
   const storedFlag = localStorage.getItem('language');
   const storedFlagAdmin = localStorage.getItem('AN');
   const [flag, setFlag] = useState(storedFlag ? storedFlag :"es");
   const flagAdmin = localStorage.getItem(storedFlagAdmin ? storedFlagAdmin : null);
   const { t, i18n } = useTranslation("global");
   const { logout } = useContext(AuthContext);
   const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
   };

   const handleOpenTranslation = (event) => {
      setOpenMenuTranslation(event.currentTarget);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   const handleCloseTranslation = () => {
      setOpenMenuTranslation(null);
   };

   const handleChangeTranslation = (flag) => {
     
      if (flag !== null) {
         setFlag(flag);
         localStorage.setItem('language', flag);
         const storedFlag = localStorage.getItem('language');
         if (storedFlag === "en") {
            i18n.changeLanguage("en")
         }
         if (storedFlag === "es") {
            i18n.changeLanguage("es")
         }
         handleCloseTranslation();
      }
   };

   const onLogout = () => {
      logout();
      handleCloseUserMenu();
      navigate('/login')
   };

   useEffect(()=>{

   },[admin,flagAdmin]);

   return (
      <AppBar position="fixed" className='navBar' open={openAppBar}>
         <Toolbar disableGutters className='firstToolbar'>
            <Toolbar className='navToolbar'>
               {/*   <IconButton sx={{ marginRight: openAppBar ? 0 : 2, ml: openAppBar ? 0 : 1, }} onClick={ToggleDrawer} aria-label='icon-menu' >
                  {openAppBar ?
                     <KeyboardDoubleArrowLeftIcon aria-label='icon-collapse' />
                     :
                     <MenuIcon aria-label='icon-menu' />
                  }
               </IconButton> */}
            </Toolbar>
            <Box className="boxDivider">
               <p className='userNavTex'>{admin} </p>
               <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} size='small'>
                     <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
               </Tooltip>
               <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
               >
                  <MenuItem onClick={onLogout}>
                     <Typography textAlign="center">{t("AppBar.logout")}</Typography>
                  </MenuItem>
               </Menu>
               <IconButton onClick={handleOpenTranslation} size='small'>
                  {flag === "en" ? <img src={`${flag_en}`} alt="flag en" className="flag_es" />
                     : <img src={`${flag_es}`} alt="flag es" className="flag_es" />}
               </IconButton>
               <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={openMenuTranslation}
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  open={Boolean(openMenuTranslation)}
                  onClose={handleCloseTranslation}
               >
                  {flag === "es" ?
                     <MenuItem onClick={() => handleChangeTranslation("en")}>
                        <img src={`${flag_en}`} alt="flag en" className="flag_es" />
                     </MenuItem>
                     :
                     <MenuItem onClick={() => handleChangeTranslation("es")}>
                        <img src={`${flag_es}`} alt="flag es" className="flag_es" />
                     </MenuItem>
                  }
               </Menu>
            </Box>
         </Toolbar>
         <Divider />
      </AppBar>
   );
}
export default AppBarAdmin;

const drawerWidth = 255;
const AppBar = styled(MuiAppBar, {
   shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
   zIndex: theme.zIndex.drawer + 1,
   boxShadow: "none",
   transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   }),
}));