export const c_primary = '#8353E2';// purple strong
export const c_primary_l = "#F5F2FD";//purple light
export const c_secondary = '#e857df';//pink 

//UserDetail
export const c_seccion = "#4d79fd"; //headerseccion
export const c_disabled="#a8abb1"
export const c_error="#fcf2f1";
export const c_error_s="#d32f2f";

export const yellow="#343807";
export const yellow_back="#FAFBE5"
export const c_success="#096D2A";
export const c_success_back="#EDFEF2";
export const blue="#4069E5";
export const blue_back="#F1F4FD";
export const orange="#ED7D2D";
export const orange_back="#FEF6F1";
export const cyan="#00BDD6";
export const cyan_back="#EBFDFF";

