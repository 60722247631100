import { Alert, LinearProgress} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

function AlertGeneral({ open, setOpen, severity, message,autoHideDuration, progress }) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    return (
        <div>
            <Snackbar open={open}
                autoHideDuration={autoHideDuration ? autoHideDuration :6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                    {progress ?<LinearProgress color="primary" />: null }
                    
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AlertGeneral;
