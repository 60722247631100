
import { lazy } from "react";
import { AdminIcon, BlockedIcon, CoursesIcon, DashboardIcon, MembershipsIcon, PathIcon, ReportsIcons, SettingsIcon, TransationsIcon, UsersIcon } from "../utils/constants/IconsConsts";
import MembershipManagment from "../views/membershipManagement";

//ERROR
const NoPermission = lazy (()=> import("../views/noPermission"));

//Components viws Admin
const MultipleSubscription = lazy(() => import("../views/multipleSubscription"));
const Admins = lazy(() => import("../views/admins"));
const DetailAdmin = lazy (()=> import("../views/admins/DetailAdmin"));
const NewAdmin = lazy (()=> import("../views/admins/NewAdmin"));
const Settings = lazy (()=> import("../views/settings"));

//Components views Connect
const Users = lazy(() => import("../views/connect/users"));
const UserNoMembership = lazy(() => import("../views/connect/users/DetailUserNoMembership"));
const UserDetailsTabs = lazy(() => import("../views/connect/users/DetailUserTabs"));
const NewUser = lazy(() => import("../views/connect/users/NewUser"));
const AssignMembership = lazy(() => import("../components/views/memberships/AssignMembership"));
const HomeConnect = lazy(() => import("../views/connect/Home"));
const MembershipsConnect = lazy(() => import("../views/connect/memberships"))
const DetailMembershipsConnect = lazy(() => import("../views/connect/memberships/DetailMembership"));
const NewMembership = lazy(() => import("../views/connect/memberships/NewMembership"));
const Courses = lazy(() => import("../views/courses/index"));
const NewCourse = lazy(() => import("../views/courses/NewCourse"));
const DetailCourse = lazy(() => import("../views/courses/DetailCourse"));
const LearningPaths = lazy(() => import("../views/learningPaths"));
const DetailLearningPath = lazy(() => import("../views/learningPaths/DetailLearningPath"));
const NewLearningPath = lazy(() => import("../views/learningPaths/NewLearningPath"));

//HomeViews
const CoursesHome= lazy(()=> import("../views/home/HomeCoursesRouter"));

//Report
const Reports = lazy(()=> import("../views/reports"));


const CoursesRouter={
    title: "COURSES",
    screens: [
        {
            name: "Dashboard",
            url: "/dashboard",
            icon: DashboardIcon,
            component: CoursesHome
        },
        {
            url: "/dashboard/crear-usuario",
            component: NewUser
        },
        {
            name: "Cursos",
            url: "/dashboard/cursos",
            icon: CoursesIcon,
            component: Courses
        },
        {
            url: "/dashboard/nuevo-curso",
            component: NewCourse
        },
        {
            url: "/dashboard/curso-detalle",
            component: DetailCourse
        },
    ]

}

//Default
const Default = {
    title: "NO_PERMISSION",
    screens: [
        {
            name: "NoPermission",
            url: "/dashboard",
            icon: BlockedIcon,
            component: NoPermission
        },
    ]
};

//Unicef
const ReportExternal = {
    title: "ALLIANCE_UNICEF",
    screens: [
        {
            name: "Reportes",
            url: "/dashboard",
            icon: ReportsIcons,
            component: Reports
        },
        {
            url: "/dashboard/reportes",
            icon: ReportsIcons,
            component: Reports
        },
    ]
};


//Router Connect
const Connect = {
    title: "CONNECT",
    screens: [
        {
            name: "Dashboard",
            url: "/dashboard",
            icon: DashboardIcon,
            component: HomeConnect
        },
        {
            name: "Usuarios",
            url: "/dashboard/usuarios",
            icon: UsersIcon,
            component: Users
        },
        {
            url: "/dashboard/usuario",
            component: UserNoMembership,
        },
        {
            url: "/dashboard/usuario-con-membresia",
            component: UserDetailsTabs
        },
        {
            url: "/dashboard/crear-usuario",
            component: NewUser
        },
        {
            name: "Cursos",
            url: "/dashboard/cursos",
            icon: CoursesIcon,
            component: Courses
        },
        {
            url: "/dashboard/nuevo-curso",
            component: NewCourse
        },
        {
            url: "/dashboard/curso-detalle",
            component: DetailCourse
        },
        {
            name: "Membresías",
            url: "/dashboard/membresias",
            icon: MembershipsIcon,
            component: MembershipManagment
        },
        {
            url: "/dashboard/connect/membresias",
            component: MembershipsConnect
        },
        {
            url: "/dashboard/membresia-detalle",
            component: DetailMembershipsConnect
        },
        {
            url: "/dashboard/crear-membresia",
            component: NewMembership
        },
    ]
};

//Super Admin
const SuperAdmin = {
    title: "SUPER_ADMIN",
    screens: [
        {
            name: "Dashboard",
            url: "/dashboard",
            icon: DashboardIcon,
            component: HomeConnect
        },
        {
            name: "Admin",
            url: "/dashboard/admins",
            icon: AdminIcon,
            component: Admins
        },
        {
            url: "/dashboard/administrador",
            component: DetailAdmin,
        },
        {
            url: "/dashboard/crear-admin",
            component: NewAdmin
        },
        {
            name: "Usuarios",
            url: "/dashboard/usuarios",
            icon: UsersIcon,
            component: Users
        },
        {
            url: "/dashboard/usuario",
            component: UserNoMembership,
        },
        {
            url: "/dashboard/usuario-con-membresia",
            component: UserDetailsTabs
        },
        {
            url: "/dashboard/crear-usuario",
            component: NewUser
        },
        {
            name: "Cursos",
            url: "/dashboard/cursos",
            icon: CoursesIcon,
            component: Courses
        },
        {
            url: "/dashboard/nuevo-curso",
            component: NewCourse
        },
        {
            url: "/dashboard/curso-detalle",
            component: DetailCourse
        },
        {
            name: "Membresías",
            url: "/dashboard/membresias",
            icon: MembershipsIcon,
            component: MembershipManagment
        },
        {
            url: "/dashboard/connect/membresias",
            component: MembershipsConnect
        },
        {
            url: "/dashboard/membresia-detalle",
            component: DetailMembershipsConnect
        },
        {
            url: "/dashboard/crear-membresia",
            component: NewMembership
        },
        {
            url: "/dashboard/asignar-membresia",
            component: AssignMembership

        },
        {
            name: "LearningPaths",
            url: "/dashboard/learning-paths",
            icon: PathIcon,
            component: LearningPaths
        },
        {
            url: "/dashboard/detail-learning-path",
            component: DetailLearningPath
        },
        {
            url: "/dashboard/new-learning-path",
            component: NewLearningPath
        },
        {
            url: "/dashboard/multiples-subscripciones",
            component: MultipleSubscription
        },
        {
            name: "Transacciones",
            url: "/dashboard/transacciones",
            icon: TransationsIcon,
            component: HomeConnect
        },
        {
            name: "Reportes",
            url: "/dashboard/reportes",
            icon: ReportsIcons,
            component: Reports
        },
        {
            name: "Ajustes",
            url: "/dashboard/ajustes",
            icon: SettingsIcon,
            component: Settings
        },
    ]
};

export const getRouter = () => {
    const role = localStorage.getItem('AR');
    switch (role){
        case 'CONNECT_MANAGER': return Connect;
        case 'CONNECT_ANALYST': return Connect;
        case 'COURSES': return CoursesRouter;
        case 'SUPER_ADMIN': return SuperAdmin;
        case 'ALLIANCE_UNICEF': return ReportExternal;
        case 'ERROR': return Default;
        default: return Default;
    }
}