import {Grid } from "@mui/material";
import "../../assets/styles/views/users.css";
import ColorCard from "../../components/general/Cards/ColorCard";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { AddCard, AddMembership } from "../../utils/constants/IconsConsts";
import { useNavigate } from "react-router-dom";

function MembershipManagment() {
    const { t } = useTranslation("global");
    const navigate = useNavigate();

    return (
        <Grid container direction="row" >
            <Grid item lg={12} xs={12} className="titleHeader-users" sx={{ mb: 2 }}>
                <h1 className="h1-users">{t("Memberships.admin")}</h1>
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("Membership.membership")}
                    description={t("MembershipManagment.title")}
                    list={[
                        `${t("MembershipManagment.task.list")}`,
                        `${t("MembershipManagment.task.search")}`,
                        `${t("MembershipManagment.task.edit")}`,
                        `${t("MembershipManagment.task.export")}`,
                    ]}
                    onclickCard={() => navigate("/dashboard/connect/membresias")}
                />
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("Memberships.new_membership")}
                    iconCard={AddCard}
                    description={t("MembershipManagment.description.create")}
                    list={["B2C", "Talleres"]}
                    blueCard={true}
                    onclickCard={() => navigate("/dashboard/crear-membresia")}
                />
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("Memberships.export")}
                    iconCard={<FileDownloadOutlinedIcon />}
                    description={t("MembershipManagment.description.export")}
                />
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("User.assign_membership")}
                    iconCard={AddMembership}
                    description={t("MembershipManagment.description.assign")}
                    list={[
                        `${t("MembershipManagment.task.search")}`,
                        `${t("MembershipManagment.task.select")}`,
                    ]}
                    onclickCard={() => navigate("/dashboard/asignar-membresia")}
                />
            </Grid>
           <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    yellowCard={true}
                    title={t("MultipleSubscription.multipleSubscription")}
                    iconCard={AddMembership}
                    description={t("MembershipManagment.description.multi")}
                    list={[
                        `${t("MembershipManagment.task.download_template")}`,
                        `${t("MembershipManagment.task.fill")}`,
                        `${t("MembershipManagment.task.attach")}`
                    ]}
                    onclickCard={() => navigate("/dashboard/multiples-subscripciones")}
                />
            </Grid> 
        </Grid>
    )
}
export default MembershipManagment;

